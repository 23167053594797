@font-face {
  font-family: 'bonifont';
  src:
    url('fonts/bonifont.ttf?9x6691') format('truetype'),
    url('fonts/bonifont.woff?9x6691') format('woff'),
    url('fonts/bonifont.svg?9x6691#bonifont') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="b-font-"], [class*=" b-font-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'bonifont' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.b-font-check-in:before {
  content: "\e9e9";
}
.b-font-towel:before {
  content: "\e914";
}
.b-font-shorts:before {
  content: "\e915";
}
.b-font-repellent:before {
  content: "\e95f";
}
.b-font-sunrise:before {
  content: "\e962";
}
.b-font-sunset:before {
  content: "\e9bb";
}
.b-font-baby-carriage:before {
  content: "\e97f";
}
.b-font-water-shoes:before {
  content: "\e9e6";
}
.b-font-flag:before {
  content: "\e9e4";
}
.b-font-music:before {
  content: "\e99d";
}
.b-font-building:before {
  content: "\e99e";
}
.b-font-safe-buy:before {
  content: "\e99f";
}
.b-font-helmet:before {
  content: "\e9a0";
}
.b-font-fridge:before {
  content: "\e9a1";
}
.b-font-ventilation:before {
  content: "\e9a2";
}
.b-font-house:before {
  content: "\e9a3";
}
.b-font-satellite:before {
  content: "\e9a4";
}
.b-font-rock:before {
  content: "\e9a5";
}
.b-font-bathtub:before {
  content: "\e9a6";
}
.b-font-air-conditioning:before {
  content: "\e9a7";
}
.b-font-tv:before {
  content: "\e9a8";
}
.b-font-crock:before {
  content: "\e9a9";
}
.b-font-bike:before {
  content: "\e9aa";
}
.b-font-plus-circle:before {
  content: "\e9ab";
}
.b-font-snowflake:before {
  content: "\e9ac";
}
.b-font-baby-seat:before {
  content: "\e9ad";
}
.b-font-day-use:before {
  content: "\e9af";
}
.b-font-translator:before {
  content: "\e9b0";
}
.b-font-bus:before {
  content: "\e9b1";
}
.b-font-taxi:before {
  content: "\e9b2";
}
.b-font-suitcase:before {
  content: "\e9b3";
}
.b-font-people:before {
  content: "\e9b4";
}
.b-font-guide:before {
  content: "\e9b5";
}
.b-font-airplane:before {
  content: "\e9b6";
}
.b-font-safe-box:before {
  content: "\e9b7";
}
.b-font-horse:before {
  content: "\e9b8";
}
.b-font-car:before {
  content: "\e9b9";
}
.b-font-steering-wheel:before {
  content: "\e9ba";
}
.b-font-sneakers:before {
  content: "\e9bc";
}
.b-font-trouser:before {
  content: "\e9bd";
}
.b-font-snack:before {
  content: "\e9be";
}
.b-font-zipline:before {
  content: "\e9bf";
}
.b-font-training:before {
  content: "\e9c0";
}
.b-font-fuel-pump:before {
  content: "\e9c1";
}
.b-font-air-bag:before {
  content: "\e9c2";
}
.b-font-tourist-attraction:before {
  content: "\e9c3";
}
.b-font-store:before {
  content: "\e9c4";
}
.b-font-vehicle:before {
  content: "\e9c5";
}
.b-font-cabin:before {
  content: "\e9c6";
}
.b-font-sun:before {
  content: "\e9c7";
}
.b-font-extinguisher:before {
  content: "\e9c8";
}
.b-font-flashlight:before {
  content: "\e9c9";
}
.b-font-abs:before {
  content: "\e9ca";
}
.b-font-hanger:before {
  content: "\e9cb";
}
.b-font-mount:before {
  content: "\e9cc";
}
.b-font-map:before {
  content: "\e9cd";
}
.b-font-coffee:before {
  content: "\e9ce";
}
.b-font-night:before {
  content: "\e9cf";
}
.b-font-hammock:before {
  content: "\e9d0";
}
.b-font-snorkel-flotation:before {
  content: "\e9d1";
}
.b-font-toucan:before {
  content: "\e9d2";
}
.b-font-rowing:before {
  content: "\e9d3";
}
.b-font-kayak:before {
  content: "\e9d4";
}
.b-font-action-cam:before {
  content: "\e9d5";
}
.b-font-medical-kit:before {
  content: "\e9d6";
}
.b-font-waterfall:before {
  content: "\e9d7";
}
.b-font-apple:before {
  content: "\e9d8";
}
.b-font-volleyball:before {
  content: "\e9d9";
}
.b-font-socks:before {
  content: "\e9da";
}
.b-font-disc:before {
  content: "\e9db";
}
.b-font-diving-suit:before {
  content: "\e9dc";
}
.b-font-bed:before {
  content: "\e9dd";
}
.b-font-life-jacket:before {
  content: "\e9de";
}
.b-font-binoculars:before {
  content: "\e9df";
}
.b-font-wifi:before {
  content: "\e969";
}
.b-font-locker-room:before {
  content: "\e96a";
}
.b-font-light-clothing:before {
  content: "\e96c";
}
.b-font-insect-repellent:before {
  content: "\e96d";
}
.b-font-sunscreen:before {
  content: "\e96e";
}
.b-font-pool:before {
  content: "\e96f";
}
.b-font-lake:before {
  content: "\e970";
}
.b-font-luggage-storage:before {
  content: "\e971";
}
.b-font-parking:before {
  content: "\e972";
}
.b-font-shower:before {
  content: "\e973";
}
.b-font-cash:before {
  content: "\e974";
}
.b-font-flip-flops:before {
  content: "\e975";
}
.b-font-restroom:before {
  content: "\e976";
}
.b-font-smoking-area:before {
  content: "\e977";
}
.b-font-rest-area:before {
  content: "\e978";
}
.b-font-wheelchair:before {
  content: "\e979";
}
.b-font-camera:before {
  content: "\e97a";
}
.b-font-playground:before {
  content: "\e97b";
}
.b-font-swimsuit:before {
  content: "\e97c";
}
.b-font-utensils:before {
  content: "\e97d";
}
.b-font-disabled-person:before {
  content: "\e96b";
}
.b-font-weight-kg:before {
  content: "\e941";
}
.b-font-wallet:before {
  content: "\e993";
}
.b-font-difficulty:before {
  content: "\e9ec";
}
.b-font-multi-vacancy:before {
  content: "\e9f0";
}
.b-font-simple-lock-closed:before {
  content: "\e9f1";
}
.b-font-simple-lock-open:before {
  content: "\e9f2";
}
.b-font-map-pin-filled:before {
  content: "\e9f3";
}
.b-font-map-pin:before {
  content: "\e9f6";
}
.b-font-pregnant:before {
  content: "\e9f7";
}
.b-font-height:before {
  content: "\e9f8";
}
.b-font-senior:before {
  content: "\e9f9";
}
.b-font-kid:before {
  content: "\e9fa";
}
.b-font-shopping-bag:before {
  content: "\e9e0";
}
.b-font-adult:before {
  content: "\e998";
}
.b-font-minimum-weight:before {
  content: "\e994";
}
.b-font-heart-outline:before {
  content: "\e98a";
}
.b-font-mountain:before {
  content: "\e944";
}
.b-font-ruler:before {
  content: "\e985";
}
.b-font-weight:before {
  content: "\e986";
}
.b-font-baby:before {
  content: "\e987";
}
.b-font-sliders:before {
  content: "\e984";
}
.b-font-click:before {
  content: "\e965";
}
.b-font-eclipse:before {
  content: "\e97e";
}
.b-font-share:before {
  content: "\e95d";
}
.b-font-refresh:before {
  content: "\e95e";
}
.b-font-open-lock:before {
  content: "\e999";
}
.b-font-closed-lock:before {
  content: "\e960";
}
.b-font-mail:before {
  content: "\e961";
}
.b-font-drag:before {
  content: "\e963";
}
.b-font-clock:before {
  content: "\e964";
}
.b-font-cashback:before {
  content: "\e966";
}
.b-font-bank-card:before {
  content: "\e967";
}
.b-font-account:before {
  content: "\e968";
}
.b-font-audit:before {
  content: "\e9e8";
}
.b-font-hourglass:before {
  content: "\e9fd";
}
.b-font-help:before {
  content: "\ea00";
}
.b-font-path-vertical:before {
  content: "\ea01";
}
.b-font-path-horizontal:before {
  content: "\ea02";
}
.b-font-broom:before {
  content: "\ea03";
}
.b-font-security:before {
  content: "\ea06";
}
.b-font-forward:before {
  content: "\ea07";
}
.b-font-alerts:before {
  content: "\e9e7";
}
.b-font-schedule:before {
  content: "\e9e1";
}
.b-font-financial-transaction:before {
  content: "\e9e2";
}
.b-font-return-money:before {
  content: "\e9e3";
}
.b-font-desktop-config:before {
  content: "\e995";
}
.b-font-clipboard-config:before {
  content: "\e996";
}
.b-font-arrow-percentage:before {
  content: "\e997";
}
.b-font-edit-info:before {
  content: "\e98f";
}
.b-font-person:before {
  content: "\e990";
}
.b-font-redo:before {
  content: "\e98d";
}
.b-font-external-link:before {
  content: "\e98c";
}
.b-font-integration:before {
  content: "\e988";
}
.b-font-document:before {
  content: "\e95b";
}
.b-font-charts:before {
  content: "\e94c";
}
.b-font-shielded:before {
  content: "\e94d";
}
.b-font-saturn:before {
  content: "\e94e";
}
.b-font-business:before {
  content: "\e94f";
}
.b-font-open-padlock:before {
  content: "\e950";
}
.b-font-tickets:before {
  content: "\e951";
}
.b-font-route:before {
  content: "\e952";
}
.b-font-online-shopping:before {
  content: "\e953";
}
.b-font-stopwatch:before {
  content: "\e954";
}
.b-font-professional:before {
  content: "\e955";
}
.b-font-square-check:before {
  content: "\e949";
}
.b-font-message-ballon:before {
  content: "\e94a";
}
.b-font-floater:before {
  content: "\e94b";
}
.b-font-personal-info:before {
  content: "\e9fc";
}
.b-font-redeem-code:before {
  content: "\e902";
}
.b-font-preferences:before {
  content: "\e940";
}
.b-font-purchase:before {
  content: "\e942";
}
.b-font-key:before {
  content: "\e943";
}
.b-font-limber-li:before {
  content: "\e9ea";
}
.b-font-limber-lim:before {
  content: "\e9eb";
}
.b-font-hamburguer-menu:before {
  content: "\e95a";
}
.b-font-check-thick:before {
  content: "\e919";
}
.b-font-home:before {
  content: "\e991";
}
.b-font-angle-down:before {
  content: "\e91a";
}
.b-font-angle-left:before {
  content: "\e91b";
}
.b-font-angle-right:before {
  content: "\e91c";
}
.b-font-angle-up:before {
  content: "\e91d";
}
.b-font-angle-thick-down:before {
  content: "\e91e";
}
.b-font-angle-thick-left:before {
  content: "\e91f";
}
.b-font-angle-thick-right:before {
  content: "\e920";
}
.b-font-angle-thick-up:before {
  content: "\e921";
}
.b-font-arrow-down:before {
  content: "\e922";
}
.b-font-arrow-left:before {
  content: "\e923";
}
.b-font-arrow-right:before {
  content: "\e92f";
}
.b-font-arrow-up:before {
  content: "\e930";
}
.b-font-arrow-thick-down:before {
  content: "\e924";
}
.b-font-arrow-thick-left:before {
  content: "\e925";
}
.b-font-arrow-thick-right:before {
  content: "\e926";
}
.b-font-arrow-thick-up:before {
  content: "\e927";
}
.b-font-check:before {
  content: "\e928";
}
.b-font-close:before {
  content: "\e929";
}
.b-font-ellipsis:before {
  content: "\e92a";
}
.b-font-sort-down:before {
  content: "\e92b";
}
.b-font-sort-left:before {
  content: "\e92c";
}
.b-font-sort-right:before {
  content: "\e92d";
}
.b-font-sort-up:before {
  content: "\e92e";
}
.b-font-asterisk:before {
  content: "\e9ef";
}
.b-font-trash:before {
  content: "\e93e";
}
.b-font-filter:before {
  content: "\ea0a";
}
.b-font-pdf-icon:before {
  content: "\e99c";
}
.b-font-global-icon:before {
  content: "\e99b";
}
.b-font-voucher:before {
  content: "\e99a";
}
.b-font-file-export:before {
  content: "\e992";
}
.b-font-printer:before {
  content: "\e98b";
}
.b-font-dots-menu:before {
  content: "\e95c";
}
.b-font-warning:before {
  content: "\e93f";
}
.b-font-eye:before {
  content: "\e931";
}
.b-font-eye-slash:before {
  content: "\e933";
}
.b-font-facebook:before {
  content: "\e934";
}
.b-font-google .path1:before {
  content: "\e935";
  color: rgb(52, 167, 83);
}
.b-font-google .path2:before {
  content: "\e936";
  margin-left: -0.625em;
  color: rgb(233, 67, 53);
}
.b-font-google .path3:before {
  content: "\e9ae";
  margin-left: -0.625em;
  color: rgb(66, 132, 243);
}
.b-font-google .path4:before {
  content: "\e9e5";
  margin-left: -0.625em;
  color: rgb(250, 187, 5);
}
.b-font-whatsapp:before {
  content: "\ea0b";
}
.b-font-heart:before {
  content: "\e937";
}
.b-font-info:before {
  content: "\e938";
}
.b-font-minus:before {
  content: "\e939";
}
.b-font-plus:before {
  content: "\e93a";
}
.b-font-search:before {
  content: "\e93b";
}
.b-font-star:before {
  content: "\e93c";
}
.b-font-star-filled:before {
  content: "\e93d";
}
.b-font-offline-experience:before {
  content: "\e9ed";
}
.b-font-combined-experience:before {
  content: "\e9ee";
}
.b-font-copy:before {
  content: "\e989";
}
.b-font-role-indicator:before {
  content: "\e946";
}
.b-font-pin:before {
  content: "\e932";
}
.b-font-minus-circle:before {
  content: "\e900";
}
.b-font-check-circle:before {
  content: "\e901";
}
.b-font-users:before {
  content: "\ea08";
}
.b-font-user:before {
  content: "\ea09";
}
.b-font-paste:before {
  content: "\e98e";
}
.b-font-cashier-handling:before {
  content: "\e980";
}
.b-font-cashier-list:before {
  content: "\e981";
}
.b-font-cashier:before {
  content: "\e982";
}
.b-font-reports:before {
  content: "\e983";
}
.b-font-megaphone:before {
  content: "\e916";
}
.b-font-refound:before {
  content: "\e958";
}
.b-font-send:before {
  content: "\e959";
}
.b-font-add-tickets:before {
  content: "\e956";
}
.b-font-link:before {
  content: "\e957";
}
.b-font-ticket:before {
  content: "\e948";
}
.b-font-company:before {
  content: "\e947";
}
.b-font-transfer:before {
  content: "\e945";
}
.b-font-ban:before {
  content: "\e903";
}
.b-font-bars:before {
  content: "\e904";
}
.b-font-bell:before {
  content: "\e905";
}
.b-font-bell-thick:before {
  content: "\e906";
}
.b-font-calendar:before {
  content: "\e907";
}
.b-font-changes:before {
  content: "\e908";
}
.b-font-cog:before {
  content: "\e909";
}
.b-font-compass:before {
  content: "\e90a";
}
.b-font-export:before {
  content: "\e90b";
}
.b-font-handshake:before {
  content: "\e90c";
}
.b-font-image:before {
  content: "\e917";
}
.b-font-bill:before {
  content: "\e918";
}
.b-font-logout:before {
  content: "\e90d";
}
.b-font-markers:before {
  content: "\e90e";
}
.b-font-operational:before {
  content: "\e90f";
}
.b-font-partners:before {
  content: "\e910";
}
.b-font-pencil:before {
  content: "\e911";
}
.b-font-snorkel:before {
  content: "\e912";
}
.b-font-upload:before {
  content: "\e913";
}
